









import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileBlock from './ProfileBlock.vue';
import ScoresBadges from './Scores/ScoresBadges.vue';
import ScoresRanks from './Scores/ScoresRanks.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    ScoresRanks,
    ScoresBadges,
    ProfileBlockWidgetButton,
  },
})
class Scores extends Vue {}

export default Scores;
