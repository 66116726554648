




























import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';
import Cookies from 'js-cookie';

interface CroppieRef extends Vue {
  bind: (image: { url: string }) => void;
  result: (options: { type: string; format: string; circle: boolean }, callback: (output: string) => void) => void;
}

@Component
class EditPicture extends Vue {
  $refs: {
    croppieRef: CroppieRef;
  };

  image = '';
  placeholder = 'Change Photo';
  maxSize = 5 * 1000000;
  saving = false;

  mounted() {
    this.toDataURL(this.$store.state.profile.avatar || this.$store.state.account.avatar, this.bind);
  }

  toDataURL(url: string, callback: any) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function onload() {
      const reader = new FileReader();
      reader.onloadend = function onloadend() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  filesChange(fileList: File[]) {
    const uploadedFile = fileList[0];
    if (!uploadedFile) return;

    if (uploadedFile.size > this.maxSize) {
      this.placeholder = 'File too big';
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      const blobData = reader.result as string;
      const base64Data = blobData.split(',', 2)[1];
      this.handleFileUpload(base64Data);
      this.placeholder = 'Change Photo';
    };
    reader.onerror = (err) => {
      this.placeholder = "Couldn't upload file";
      console.log(err);
    };
  }

  bind(image: string) {
    if (!this.$refs.croppieRef) return;
    (this.$refs.croppieRef as CroppieRef).bind({
      url: image,
    });
  }

  handleFileUpload(fileData: string) {
    this.image = fileData;
    this.bind(`data:image/*;base64,${this.image}`);
  }

  result() {
    if (this.saving) return;
    this.saving = true;
    const options = {
      type: 'blob',
      format: 'jpeg',
      circle: true,
    };
    (this.$refs.croppieRef as CroppieRef).result(options, (output: string) => {
      const form = new FormData();
      form.append('file', output);
      form.set('sessionId', Cookies.get('sessionId'));
      form.set('fileName', `${this.makeid(14)}.jpeg`);
      const axiosDefaults: any = axios.defaults;
      axios
        .post(`${axiosDefaults.gatewayBase}/candidate/upload/picture`, form)
        .then((res) => {
          this.saving = false;
          this.$store.commit('setAvatar', res.data.url);
          this.$emit('close');
        })
        .catch((err) => {
          this.saving = false;
          console.log(err);
          this.placeholder = "Couldn't upload file";
        });
    });
  }

  makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
export default EditPicture;
