













































































/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import axios from 'axios';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import formatScore from '../../../utils/format-score';
import { responseError } from '../../../utils/response-error';

interface Repository {
  name: string;
  modifiedAt: string;
  isUpdating: boolean;
  score: number;
  skipReason: string;
}

@Component({})
class RepoList extends Vue {
  @Prop() provider: string;
  @Prop() host: string;
  @Prop() username: string;

  confirmModalOpen = false;
  accountLinked = false;
  account: any = {};
  removeLoading = false;

  loaded = false;
  _repoList: Repository[] = [];
  searchString = '';

  error = '';
  emails: string[] = [];

  mounted() {
    const getEmails = () => {
      let host = this.host;
      if (this.provider === 'github_private') {
        host = 'github_private';
      }
      return axios
        .get(`${(axios.defaults as any).gatewayBase}/candidate/sources/${host}/emails`, {
          headers: {
            'X-SESSION-ID': Cookies.get('sessionId'),
          },
        })
        .then((res) => {
          this.emails = res.data.emails;
        });
    };
    const getRepoList = () => {
      return axios
        .get(`${(axios.defaults as any).gatewayBase}/candidate/repository-scores`, {
          headers: {
            'X-SESSION-ID': Cookies.get('sessionId'),
          },
        })
        .then((res) => {
          this._repoList = res.data.repos
            .filter((repo: any) => {
              return repo.provider === this.provider || (this.host && repo.provider === this.host);
            })
            .map((repo: any) => ({ ...repo, score: repo.skipReason ? 0 : repo.score || 0 }))
            .sort((a: any, b: any) => b.score - a.score);
        });
    };

    Promise.all([getEmails(), getRepoList()]).then(() => {
      this.loaded = true;
    });

    const { providers } = this.$store.state;

    if (Array.isArray(providers)) {
      providers.forEach((provider) => {
        if (provider.provider !== this.provider) return;
        if (provider.host !== this.host) return;
        if (provider.provider === 'gitlab.com') return;
        // if (repo.userName !== this.username) return;
        this.accountLinked = true;
        this.account = provider;
      });
    }
  }

  saveEmails() {
    let host = this.host;
    if (this.provider === 'github_private') {
      host = 'github_private';
    }
    axios.post(
      `${(axios.defaults as any).gatewayBase}/candidate/sources/${host}/emails`,
      {
        emails: this.emails,
      },
      {
        headers: {
          'X-SESSION-ID': Cookies.get('sessionId'),
        },
      },
    );
  }

  onEmailsChange() {
    this.saveEmails();
    this.$emit('emailsChanged');
  }

  openConfirmModal() {
    this.confirmModalOpen = true;
  }

  closeConfirmModal() {
    this.confirmModalOpen = false;
  }

  removeIntegration() {
    this.removeLoading = true;

    axios
      .put(`${(axios.defaults as any).gatewayBase}/user/${this.account.provider}/remove`, {
        sessionID: Cookies.get('sessionId'),
        host: this.account.host,
        userName: this.account.userName,
      })
      .then(() => {
        this.accountLinked = false;
        this.removeLoading = false;
        this.confirmModalOpen = false;

        this.$store.dispatch('refreshProfile');
      })
      .catch((err) => {
        this.error = responseError(err, 'Request failed');
        this.removeLoading = false;
        this.confirmModalOpen = false;
      });
  }

  get repoList() {
    return this._repoList.filter(
      (repo) => repo.name && repo.name.toLowerCase().includes(this.searchString.toLowerCase()),
    );
  }

  formatScore(score: number): string {
    return formatScore(score, true);
  }

  formatDate(date: string): string {
    return format(new Date(date), 'MMM dd, yyyy');
  }

  formatHost(host: string): string {
    switch (host) {
      case 'github.com':
        return 'GitHub';
      case 'gitlab.com':
        return 'GitLab';
      case 'bitbucket':
        return 'Bitbucket';
      default:
        return host;
    }
  }
}

export default RepoList;
