





















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import WorkExperienceForm from './WorkExperience/WorkExperienceForm.vue';
import PortfolioForm from './Portfolio/PortfolioForm.vue';
import EducationForm from './Education/EducationForm.vue';
import { responseError } from '../../utils/response-error';
import { workexperience } from '../../store/workexperience';
import { portfolio } from '../../store/portfolio';
import { education } from '../../store/education';
import ProfileEditModal from './ProfileEditModal.vue';
import AboutEditModal from './AboutEditModal.vue';

@Component({
  components: {
    AboutEditModal,
    ProfileEditModal,
    WorkExperienceForm,
    PortfolioForm,
    EducationForm,
  },
})
class ProfileStrengthWizard extends Vue {
  errorMessage: string = '';
  saving = false;
  stepIndex = 0;

  editSkills: any[] = [];
  wrongSkill: string = '';
  selectError = false;

  workExperience: any = {
    id: 0,
    title: '',
    companyLogo: '',
    companyName: '',
    location: '',
    startDateMonth: undefined,
    startDateYear: undefined,
    endDateMonth: undefined,
    endDateYear: undefined,
    currentlyWorkingHere: false,
    description: '',
    highlightedTechnologies: [],
    otherTechnologies: [],
  };

  portfolio: any = {
    id: -1,
    picture: '',
    picturePreview: '',
    title: '',
    companyName: '',

    role: '',
    description: '',
    highlightedSkills: [],
    skills: [],

    currentlyWorkingHere: false,
    startDateMonth: null,
    startDateYear: null,
    endDateMonth: null,
    endDateYear: null,

    demoUrl: '',
    sourceUrl: '',
  };

  education: any = {
    id: -1,
    school: '',
    degree: '',
    field: '',
    startMonth: null,
    startYear: null,
    endMonth: null,
    endYear: null,
    currentlyStudyingHere: false,
    grade: '',
    description: '',
  };

  get completion() {
    return this.$store.state.account.profileCompletion || {};
  }

  get currentStep() {
    return this.steps[this.stepIndex];
  }

  get isLastStep() {
    return this.stepIndex === this.steps.length - 1 || this.steps.length === 0;
  }

  get steps() {
    const steps = [];
    const {
      name,
      location,
      contactInfo,
      socialLinks,
      about,
      workExperience,
      // eslint-disable-next-line
      portfolio,
      // eslint-disable-next-line
      education,
    } = this.completion;

    if (!about) steps.push('Intro');
    if (!(name && location && contactInfo && socialLinks)) steps.push('Profile');
    if (!workExperience) steps.push('Work experience');
    if (!portfolio) steps.push('Portfolio');
    if (!education) steps.push('Education');

    return steps;
  }

  created() {
    // tech stack
    const skills = this.$store.state.profile.techStack;
    this.editSkills = [...skills].map((skill) => ({ ...skill }));
  }

  fetchSkills(query: string, resolve: any) {
    this.$store.dispatch('getSkillSuggestion', query).then((skills: string[]) => {
      resolve(skills);
    });
  }

  updateWorkExperience(field: string, value: any) {
    this.workExperience[field] = value;
  }

  updatePortfolio(field: string, value: any) {
    this.portfolio[field] = value;
  }

  updateEducation(field: string, value: any) {
    this.education[field] = value;
  }

  saveWorkExperience() {
    return this.$store.dispatch(workexperience.actions.CreateWorkExperience, this.workExperience);
  }

  savePortfolio() {
    return this.$store.dispatch(portfolio.actions.CreatePortfolio, this.portfolio);
  }

  saveEducation() {
    return this.$store.dispatch(education.actions.CreateEducation, this.education);
  }

  next() {
    if (this.isLastStep) {
      this.$emit('close');
      return;
    }
    this.stepIndex += 1;
  }

  async save() {
    // @ts-ignore
    const valid = await this.$refs.form.validate();
    if (!valid) return;
    const username = this.$store.state.account.username;
    let savePromise: any;
    if (this.currentStep === 'Intro') {
      // @ts-ignore
      savePromise = this.$refs.aboutEditModal.save;
    }
    if (this.currentStep === 'Profile') {
      // @ts-ignore
      savePromise = this.$refs.profileEditModal.save;
    }
    if (this.currentStep === 'Work experience') {
      savePromise = this.saveWorkExperience;
    }
    if (this.currentStep === 'Portfolio') {
      savePromise = this.savePortfolio;
    }
    if (this.currentStep === 'Education') {
      savePromise = this.saveEducation;
    }
    if (!savePromise) return;
    this.saving = true;
    savePromise()
      .then(() => {
        this.saving = false;
        if (this.currentStep === 'Work experience') {
          this.$store.dispatch('getProfile', { username, type: 'user' });
        }
        if (this.currentStep === 'Portfolio') {
          this.$store.dispatch(portfolio.actions.GetPortfolio, username);
        }
        if (this.currentStep === 'Education') {
          this.$store.dispatch(education.actions.AssignEducation, username);
        }
        this.next();
      })
      .catch((err: any) => {
        this.saving = false;
        this.errorMessage = responseError(err);
        this.$sentry.captureException(err);
      });
  }
}

export default ProfileStrengthWizard;
