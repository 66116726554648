import roundScore from './round-score';

export default function formatScore(
  score: number = 0,
  round: boolean = false,
  intlOptions: Intl.NumberFormatOptions = {},
): string {
  const rounded = round ? roundScore(score) : score;

  // @ts-ignore
  const formatter =
    typeof Intl !== 'undefined'
      ? Intl.NumberFormat('en', intlOptions)
      : {
          format(num: number): string {
            return String(num || 0);
          },
        };
  if (!formatter || !formatter.format) return String(rounded || 0);
  return formatter.format(rounded);
}
