


























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import share from '../../utils/share';

@Component({})
class Share extends Vue {
  @Prop({ type: String, default: 'Share your profile:' }) text: string;
  username: string = '';
  popupWidth: number = 800;
  popupHeight: number = 480;

  created() {
    this.username = this.$store.state.account.username;
  }

  shareTwitter() {
    this.$ga.event('profilePage', 'Share', 'Twitter-onSite');
    share.twitter(
      `https://profile.codersrank.io/user/${this.username}?utm_source=twitter&utm_medium=social&utm_campaign=profile_share`,
      'Check my 360-degree coder profile on #CodersRank and create yours now.',
    );
  }

  shareLinkedin() {
    this.$ga.event('profilePage', 'Share', 'LinkedIn-onSite');
    share.linkedin(
      `https://profile.codersrank.io/user/${this.username}?utm_source=linkedin&utm_medium=social&utm_campaign=profile_share`,
    );
  }

  shareFacebook() {
    this.$ga.event('profilePage', 'Share', 'Facebook-onSite');
    share.facebook(
      `https://profile.codersrank.io/user/${this.username}?utm_source=facebook&utm_medium=social&utm_campaign=profile_share`,
      'Check my 360-degree coder profile on #CodersRank and create yours now.',
    );
  }
}

export default Share;
