






























import Vue from 'vue';
import Component from 'vue-class-component';
import roundScore from '../../utils/round-score';
import formatScore from '../../utils/format-score';
import TechnologyCard from './Technologies/TechnologyCard.vue';
import ProfileBlock from './ProfileBlock.vue';

interface Library {
  name: string;
  country?: number;
  countryAll?: number;
  score: number;
  topXPercent?: number;
  worldWideAll?: number;
  technologies: string[];
}

@Component({
  components: {
    ProfileBlock,
    TechnologyCard,
  },
})
class Technologies extends Vue {
  currentGroup: string | null = null;

  sortByScoreDesc(arr: any) {
    arr.sort((a: any, b: any) => {
      if (a.score > b.score) return -1;
      return 1;
    });
  }

  formatScore(score: number) {
    return formatScore(score);
  }

  librariesInGroup(group: string): number {
    return this.librariesSorted.filter((lib) => lib.technologies.includes(group)).length;
  }

  get groups() {
    const groups: string[] = [];
    this.librariesSorted.forEach((lib) => {
      lib.technologies.forEach((tech) => {
        if (!groups.includes(tech)) groups.push(tech);
      });
    });
    groups.sort();
    return groups;
  }

  get librariesFiltered(): Library[] {
    if (!this.currentGroup) return this.librariesSorted;
    return this.librariesSorted.filter((lib) => lib.technologies.includes(this.currentGroup as string));
  }

  get librariesSorted(): Library[] {
    const { scoreByLibraries } = this.$store.state.profile;

    const libraries = Object.keys(scoreByLibraries).map((libName: string) => {
      const lib: Library = scoreByLibraries[libName];
      return {
        name: libName,
        score: roundScore(lib.score),
        technologies: lib.technologies,
        topXPercent: lib.topXPercent,
        worldWideAll: lib.worldWideAll,
      };
    });
    this.sortByScoreDesc(libraries);

    return libraries;
  }

  get isMyProfile() {
    return this.$store.state.isLoggedIn && this.$store.getters.shouldShowPrivateView;
  }
}

export default Technologies;
