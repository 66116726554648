


































































import Component from 'vue-class-component';
import Vue from 'vue';
import { detailedscore } from '../../../store/detailedscore';
import formatScore from '../../../utils/format-score';

@Component
class DetailedSkillModal extends Vue {
  colors: string[] = [
    '#50B0BA',
    '#387EED',
    '#FF5858',
    '#25CDEB',
    '#FDD835',
    '#76CA61',
    '#B0763F',
    '#512DA8',
    '#ED3DC6',
    '#24565A',
    '#B9C0C2',
  ];

  get chartData() {
    return this.$store.getters[detailedscore.getters.ChartData];
  }

  get listOfSources() {
    return this.$store.getters[detailedscore.getters.SortedScores];
  }

  get language() {
    return this.$store.state.detailedscore.language;
  }

  formatScore(score: number): string {
    return formatScore(score, true);
  }

  formatSource(source: string): string {
    if (source === 'StackOverflow') {
      return 'Stack Overflow';
    }

    return source;
  }
}
export default DetailedSkillModal;
