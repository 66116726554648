











































import Vue from 'vue';
import Component from 'vue-class-component';
import { gitlab } from '../../../../store/gitlab';

@Component({})
class ProvideSelfHostedDetails extends Vue {
  token = '';
  url = '';
  get isLoading() {
    return this.$store.state.gitlab.loading;
  }

  submit() {
    // @ts-ignore
    this.$refs.form.validate().then((valid) => {
      if (valid) {
        this.$store.dispatch(gitlab.actions.StartGitlabSelfHostedAuth, { token: this.token, url: this.url });
      }
    });
  }
}
export default ProvideSelfHostedDetails;
