































import Vue from 'vue';
import Component from 'vue-class-component';
import StartStep from './BitbucketModal/StartStep.vue';
import ProvideCredentialsStep from './BitbucketModal/ProvideCredentials.vue';
import ProgressStep from './BitbucketModal/ProgressStep.vue';
import DoneStep from './BitbucketModal/DoneStep.vue';

@Component({})
class BitbucketModal extends Vue {
  components = {
    0: StartStep,
    1: ProvideCredentialsStep,
    2: ProgressStep,
    3: DoneStep,
  };

  get currentStep() {
    return this.$store.state.bitbucket.step;
  }
}
export default BitbucketModal;
