








import Vue from 'vue';
import Component from 'vue-class-component';
import { bitbucket } from '../../../../store/bitbucket';

@Component({})
class ProgressStep extends Vue {
  get current() {
    if (this.$store.getters[bitbucket.getters.IsFinished]) {
      this.$store.dispatch(bitbucket.actions.SetStep, 3);
    }
    return this.$store.state.bitbucket.repos.completedRepos || 0;
  }

  get max() {
    return this.$store.state.bitbucket.repos.totalRepos;
  }
}
export default ProgressStep;
