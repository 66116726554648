







































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { isEmpty } from 'lodash-es';
import { privaterepo } from '../../../store/privaterepo';
import { gitlab } from '../../../store/gitlab';
import { github } from '../../../store/github';
import { bitbucket } from '../../../store/bitbucket';
import PrivateModal from '../Sources/PrivateModal.vue';
import GitlabModal from '../Sources/GitlabModal.vue';
import GithubModal from '../Sources/GithubModal.vue';
import BitbucketModal from '../Sources/BitbucketModal.vue';
import RepoList from '../Sources/RepoList.vue';

@Component({
  components: {
    PrivateModal,
    GitlabModal,
    GithubModal,
    BitbucketModal,
    RepoList,
  },
})
class ScoresSources extends Vue {
  dropdownOpened = false;
  privateSourcesOpened = false;
  privateSourcesProvider = '';
  privateSourcesHost = '';
  privateSourcesUsername = '';
  emailsChanged = false;

  $refs: {
    dropdownEl: HTMLElement;
    dropdownButtonEl: any;
  };

  openPrivateSources(provider: string, host: string, username: string = '') {
    if (!this.isOwnProfile) return;

    this.privateSourcesProvider = provider;
    this.privateSourcesHost = host;
    this.privateSourcesUsername = username;
    this.privateSourcesOpened = true;
  }

  closePrivateSources() {
    this.privateSourcesOpened = false;
    if (this.emailsChanged) {
      this.emailsChanged = false;
      this.$store.dispatch('refreshProfile', true);
    }
  }

  onEmailsChanged() {
    this.emailsChanged = true;
  }

  get isOwnProfile() {
    return this.$store.getters.shouldShowPrivateView;
  }

  get privateProfileSources() {
    return this.$store.getters.privateProfileSources;
  }

  get gitlabSourcesRepoCount() {
    if (!this.isOwnProfile) return {};

    return this.privateProfileSources
      .filter(
        (repo: any) =>
          repo.provider &&
          repo.provider !== 'github.com' &&
          repo.provider !== 'bitbucket' &&
          repo.provider !== 'github_private' &&
          repo.provider !== 'manually_added',
      )
      .reduce(
        (providers: any, repo: any) => ({ ...providers, [repo.provider]: (providers[repo.provider] || 0) + 1 }),
        {},
      );
  }

  get publicProfileSources() {
    return this.$store.getters.publicProfileSources;
  }

  get stackoverflowLinked() {
    return this.isOwnProfile
      ? this.$store.state.account.stackoverflowlinked
      : this.publicProfileSources.stackoverflowlinked;
  }

  get githubPrivateTokenLinked() {
    return this.privateProfileSources.filter((source: any) => source.provider === 'github_private').length > 0;
  }

  get gitlabLinked() {
    return !isEmpty(this.gitlabSourcesRepoCount);
  }

  get bitbucketLinked() {
    return this.privateProfileSources.filter((source: any) => source.provider === 'bitbucket').length > 0;
  }

  get githubReposCount() {
    let count = 0;
    if (this.isOwnProfile) {
      count = this.privateProfileSources.filter((m: any) => m.provider === 'github.com').length || 0;
      if (this.githubPrivateTokenLinked) {
        count += this.privateProfileSources.filter((m: any) => m.provider === 'github_private').length || 0;
      }
    } else {
      const publicGithubSources =
        this.publicProfileSources.sources &&
        this.publicProfileSources.sources.filter((s: any) => s.name === 'github.com' || s.name === 'github_private');
      if (publicGithubSources) {
        count = publicGithubSources.reduce((acc: number, curr: any) => acc + (curr.repos ? curr.repos : 0), 0) || 0;
      }
    }
    if (count === 0) return '';

    return this.formatReposCount(count);
  }

  get gitlabReposCount() {
    let count = 0;
    if (this.isOwnProfile) {
      count =
        this.privateProfileSources.filter((repo: any) => repo.provider && repo.provider === 'gitlab.com').length || 0;
    } else {
      const publicGitlabSource =
        this.publicProfileSources.sources &&
        this.publicProfileSources.sources.filter((s: any) => s.name === 'gitlab.com')[0];
      if (publicGitlabSource) {
        count = publicGitlabSource.repos || 0;
      }
    }
    if (count === 0) return '';

    return this.formatReposCount(count);
  }

  get bitbucketReposCount() {
    let count = 0;
    if (this.isOwnProfile) {
      count =
        this.privateProfileSources.filter((repo: any) => repo.provider && repo.provider === 'bitbucket').length || 0;
    } else {
      const publicBitbucketSources =
        this.publicProfileSources.sources &&
        this.publicProfileSources.sources.filter((s: any) => s.name === 'bitbucket')[0];
      if (publicBitbucketSources) {
        count = publicBitbucketSources.repos || 0;
      }
    }
    if (count === 0) return '';
    return this.formatReposCount(count);
  }

  get gitlabSelfHostedReposCount() {
    let count = 0;
    if (this.isOwnProfile) {
      count =
        this.privateProfileSources.filter(
          (repo: any) =>
            repo.provider &&
            repo.provider !== 'gitlab.com' &&
            repo.provider !== 'github.com' &&
            repo.provider !== 'bitbucket' &&
            repo.provider !== 'github_private' &&
            repo.provider !== 'manually_added',
        ).length || 0;
    } else {
      const publicGitlabSource =
        this.publicProfileSources.sources &&
        this.publicProfileSources.sources.filter((s: any) => s.name === 'gitlab.com')[0];
      if (publicGitlabSource) {
        count = publicGitlabSource.repos || 0;
      }
    }
    if (count === 0) return '';

    return this.formatReposCount(count);
  }

  get stackOverflowAnswersCount() {
    return this.$store.state.profile.stackOverflowAnswers || 0;
  }

  mounted() {
    document.addEventListener('click', this.onDocumentClick);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  formatReposCount(count: number | undefined) {
    if (count === 1) return `${count} repo`;
    return `${count || 0} repos`;
  }

  onDocumentClick(e: any) {
    const targetEl = e.target;
    const { dropdownEl, dropdownButtonEl } = this.$refs;
    if (!this.$store.state.isLoggedIn || !this.isOwnProfile || !dropdownEl || !dropdownButtonEl) return;
    if (
      (dropdownEl as HTMLElement).contains(targetEl) ||
      (dropdownButtonEl.$el as HTMLElement).contains(targetEl) ||
      dropdownEl === targetEl ||
      dropdownButtonEl.$el === targetEl
    )
      return;
    this.dropdownOpened = false;
  }

  closeGitHubModal() {
    if (this.$store.state.github.step === 3) this.$store.dispatch('refreshProfile', false);
    this.$store.dispatch(github.actions.CloseModal);
  }

  openGitHubModal() {
    if (this.githubPrivateTokenLinked) return;
    this.dropdownOpened = false;
    this.$store.dispatch(github.actions.OpenModal);
  }

  closeGitlabModal() {
    this.$store.dispatch(gitlab.actions.CloseModal);
  }

  openGitlabModal() {
    this.dropdownOpened = false;
    if (this.gitlabSourcesRepoCount['gitlab.com'] !== undefined) {
      this.$store.dispatch(gitlab.actions.SetStep, 1);
    } else {
      this.$store.dispatch(gitlab.actions.SetStep, 0);
    }
    this.$store.dispatch(gitlab.actions.OpenModal);
  }

  openBitbucketModal() {
    this.dropdownOpened = false;
    if (this.bitbucketLinked) return;
    this.$store.dispatch(bitbucket.actions.SetStep, 0);
    this.$store.dispatch(bitbucket.actions.OpenModal);
  }

  closeBitbucketModal() {
    this.$store.dispatch(bitbucket.actions.CloseModal);
  }

  closePrivateRepoModal() {
    this.$store.commit(privaterepo.mutations.CloseModal);
    if (this.$store.state.privaterepo.shouldRefreshProfileOnModalClose) {
      this.$store.dispatch('refreshProfile');
    }
  }

  openPrivateRepoModal() {
    this.dropdownOpened = false;
    this.$store.commit(privaterepo.mutations.OpenModal);
  }

  connectStackOverflow() {
    if (this.stackoverflowLinked) return;
    this.dropdownOpened = false;
    if (!this.$store.state.account.stackoverflowlinked) {
      this.$store.dispatch('startStackoverflowAuth');
    }
  }
}
export default ScoresSources;
