


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { workexperience } from '../../store/workexperience';
import { removeJSHref } from '../../utils/remove-js-href';

import EditPicture from './EditPicture.vue';
import JobInterest from './JobInterest.vue';
import EditButton from '../shared/EditButton.vue';

@Component({
  components: {
    EditPicture,
    JobInterest,
    EditButton,
  },
})
class ProfileHead extends Vue {
  city = '';
  country = '';
  editPicture = false;

  get socialLinks() {
    return this.$store.state.profile.socialLinks || {};
  }

  get isEditable() {
    return this.$store.getters.shouldShowPrivateView && this.$store.state.isLoggedIn;
  }

  get location() {
    return this.city ? `${this.city}, ${this.country}` : this.country;
  }

  get workExperience() {
    const experiences = this.$store.getters[workexperience.getters.WorkExperience].filter((job: any) => {
      return job.titles && job.titles.filter((title: any) => title.currentlyWorkingHere).length > 0;
    });

    if (!experiences.length) return '';
    const latestExperience = experiences[0];
    return `${latestExperience.titles[0].title} at ${latestExperience.companyName}`;
  }

  get isAsOthersSee() {
    return this.$route.name && this.$route.name.indexOf('AsOthersSee') > 0;
  }

  created() {
    this.city = this.$store.state.profile.city;
    this.country = this.$store.state.profile.country;
  }

  openEditPictureModal() {
    this.editPicture = true;
  }

  editProfile() {
    this.$emit('edit');
  }

  removeJSHref(href: string): string {
    return removeJSHref(href);
  }
}

export default ProfileHead;
