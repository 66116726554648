<template>
  <section class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6 improve">
          <h2>Would you like to<strong> improve your profile?</strong></h2>
          <p>
            Please read
            <a
              rel="noopener"
              href="https://blog.codersrank.io/how-to-increase-your-score-in-codersrank/"
              target="_blank"
              >this article</a
            >
            about how we calculate your experience.
          </p>
          <p class="sm">If you add additional profiles, that can also help you to gain more score.</p>
        </div>
        <div class="col-md-12 col-lg-6 footer-info">
          <div class="row">
            <div class="col-sm-6 col-md-4">
              <ul>
                <li><a href="https://codersrank.io/about-us/" target="_blank" rel="noopener">About Us</a></li>
                <li>
                  <a href="https://codersrank.io/terms-and-conditions/" target="_blank" rel="noopener"
                    >Terms and Conditions</a
                  >
                </li>
                <li>
                  <a href="https://codersrank.io/privacy-policy/" target="_blank" rel="noopener">Privacy Policy</a>
                </li>
                <li>
                  <a class="ccb__edit" style="cursor: pointer;" rel="noopener">
                    Cookie Policy
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-4">
              <ul>
                <li>
                  <a href="https://codersrank.canny.io/feature-request" target="_blank" rel="noopener"
                    >Request a feature</a
                  >
                </li>
                <li>
                  <a href="https://codersrank.canny.io/bug-report" target="_blank" rel="noopener">Report a bug</a>
                </li>
                <li><a href="mailto:info@codersrank.io">Contact</a></li>
              </ul>
            </div>
            <div class="col-sm-12 col-md-4">
              <div class="icons d-flex justify-content-between">
                <a
                  href="https://codersrank.slack.com/join/shared_invite/enQtNDU5MTI1OTg3NTM2LWJlNWEyOTkxMWYwMTMwMDI2NjMzZWJiMTc0NTJkNDI2OWVkZmE5NjRlZGEyNDg0MTk2OTE5OTliZGM0NjUzYzc"
                  target="_blank"
                  aria-label="Slack"
                  rel="noopener"
                  ><i class="fab fa-slack-hash"></i
                ></a>
                <a href="https://www.facebook.com/codersrank" target="_blank" aria-label="Facebook" rel="noopener"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a href="https://twitter.com/codersrank" target="_blank" aria-label="Twitter" rel="noopener"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
              <div class="copyright">
                <span>&copy; {{ year }} Codersrank.io</span>
              </div>
              <a class="clearbit" href="https://clearbit.com" rel="noreferer noopener" target="_blank"
                >Logos provided by Clearbit</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Footer',
    props: {
      setParentPadding: Boolean,
    },
    computed: {
      year() {
        return new Date().getFullYear();
      },
    },
    mounted() {
      window.addEventListener('resize', this.emitHeight);
      this.emitHeight();
    },
    methods: {
      emitHeight() {
        const addExtra = 20;
        const el = this.$el;
        if (!el) return;
        const height = el.offsetHeight + addExtra;
        if (this.setParentPadding && el.parentNode && el.parentNode.style) {
          el.parentNode.style.paddingBottom = `${height}px`;
        }
        this.$emit('footer-height', height);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "../../assets/scss/variables"
  section
    background: #132433 url('~assets/images/improve_bg.jpg') 40% 50% no-repeat
    color: #9cb4b5
    padding: 60px 0
    position: absolute
    bottom: 0
    left: 0
    right: 0
    h2
      font-weight: 300
      font-size: 28px
      letter-spacing: .5px
      margin-bottom: 15px
      margin-top: 0
      line-height: 1.2
      strong
        font-weight: 800
    p
      margin-bottom: 0
      font-size: 18px
      line-height: 1.4
      margin-bottom: 5px
      &.sm
        font-size: 14px
      a
        color: var(--gray-300)
        font-weight: 700
        &:hober
          color: #fff
    .icons
      text-align: center
      i
        font-size: 20px
      a
        color: var(--gray-300)
        padding: 10px
        &:hover
          color: #fff
          text-decoration: none
    ul
      margin: 0
      padding: 0
      li
        list-style: none
        padding-bottom: 10px
        a
          color: var(--gray-300)
          &:hover
            color: #fff
    .copyright
      padding-top: 2px
      text-align: center

    .clearbit
      text-align: center
      display: inline-block

  @media screen and (max-width: map-get($grid-breakpoints, lg))
    .footer-info
      margin-top: 30px
  @media screen and (max-width: map-get($grid-breakpoints, md))
    .improve
      text-align: center
      h2
        text-align: center
    .footer-info
      margin-top: 30px
    .icons
      margin-top: 10px
    .copyright
      margin-top: 10px
</style>
