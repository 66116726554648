






























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { privaterepo } from '../../../store/privaterepo';

@Component
class PrivateModal extends Vue {
  file = null;
  shouldDisableButtonForever = false;
  showConfirmDialog = false;
  repoToDelete = '';
  multiTokenExists = false;

  mounted() {
    this.$store.dispatch(privaterepo.actions.QueryRepoList);
  }

  closeErrorModal() {
    this.$store.commit(privaterepo.mutations.ErrorModalClose);
  }

  preventDefault(e: any) {
    e.preventDefault();
  }

  onFileDrop(e: any) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (!files.length) return;
    this.file = files[0];
  }

  onFileChange(e: any) {
    const files = e.target.files;
    if (!files.length) return;
    this.file = files[0];
  }

  @Watch('file')
  uploadFile() {
    if (this.file !== null) {
      this.$store.dispatch(privaterepo.actions.UploadFile, this.file);
      this.file = null;
      (this.$refs.uploadfile as any).value = null;
    }
  }

  confirmDialog(reponame: string) {
    this.repoToDelete = reponame;
    this.showConfirmDialog = true;
  }

  remove() {
    this.$store.dispatch(privaterepo.actions.DeleteRepo, this.repoToDelete);
    this.showConfirmDialog = false;
  }

  get shouldShowCalculateScoreButton() {
    return this.$store.getters[privaterepo.getters.ShouldShowCalculateButton];
  }

  get multiToken(): boolean {
    if (!this.multiTokenExists) {
      this.multiTokenExists = this.$route.query !== null && this.$route.query.multiTokenButton !== undefined;
    }
    return this.multiTokenExists;
  }

  formatDate(dateString: string): string {
    return format(new Date(dateString), 'yyyy-MM-dd HH:mm');
  }

  shouldShow() {
    return !this.multiToken && (!this.shouldShowCalculateScoreButton.show || this.shouldDisableButtonForever);
  }

  calculateScore() {
    this.$store.dispatch('refreshProfile');
    this.$store.commit(privaterepo.mutations.CloseModal);
    this.shouldDisableButtonForever = true;
  }

  get calculateScoreTitle() {
    if (this.shouldShowCalculateScoreButton.show) {
      return null;
    }
    if (this.shouldShowCalculateScoreButton.finished === true) {
      return 'Please upload at least one repository!';
    }
    return 'Storing in progress. Please wait.';
  }
}
export default PrivateModal;
