








import Vue from 'vue';
import Component from 'vue-class-component';
import { github } from '../../../../store/github';

@Component({})
class ProgressStep extends Vue {
  get current() {
    if (this.$store.getters[github.getters.IsFinished]) {
      this.$store.dispatch(github.actions.SetStep, 3);
    }
    return this.$store.state.github.repos.completedRepos || 0;
  }

  get max() {
    return this.$store.state.github.repos.totalRepos;
  }
}
export default ProgressStep;
