














import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
class SidebarInvite extends Vue {}

export default SidebarInvite;
