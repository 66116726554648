








import Vue from 'vue';
import Component from 'vue-class-component';
import { gitlab } from '../../../../store/gitlab';

@Component({})
class ProgressStep extends Vue {
  get current() {
    if (this.$store.getters[gitlab.getters.SelectedProgress].completedRepos === this.max) {
      this.$store.dispatch(gitlab.actions.SetStep, 3);
    }
    return this.$store.getters[gitlab.getters.SelectedProgress].completedRepos || 0;
  }

  get max() {
    return this.$store.getters[gitlab.getters.SelectedProgress].totalRepos;
  }
}
export default ProgressStep;
