

















































import Vue from 'vue';
import Component from 'vue-class-component';
import highlight from '../../ui/utils/highlight';

@Component
class ProfileStrength extends Vue {
  wizardOpened = false;
  swiper: any;
  skippedKeys: string[] = [];
  items: any = {
    about: {
      name: 'About Me',
      action: 'Write about yourself',
      icon: ['fas', 'fa-user'],
      children: {
        profilePicture: {
          name: 'Profile picture',
          icon: ['fas', 'fa-file-image'],
        },
        name: {
          name: 'Name',
          action: 'Add your name',
          icon: ['fas', 'fa-user'],
        },
        location: {
          name: 'Location',
          action: 'Add location',
          icon: ['fas', 'fa-map-marker-alt'],
        },
        contactInfo: {
          name: 'Contact info',
          action: 'Add contact information',
          icon: ['fas', 'fa-clipboard-list'],
        },
        socialLinks: {
          name: 'Social links',
          action: 'Add social links',
          icon: ['fas', 'fa-user-friends'],
        },
      },
    },
    workExperience: {
      name: 'Work Experience',
      action: 'Add work experience',
      icon: ['fas', 'fa-clipboard-list'],
    },
    portfolio: {
      name: 'Portfolio',
      action: 'Add portfolio',
      icon: ['fas', 'fa-star'],
    },
    education: {
      name: 'Education',
      action: 'Add education',
      icon: ['fas', 'fa-graduation-cap'],
    },
  };

  mounted() {
    // Import dynamically as we don't need those on other pages
    Promise.all([
      import(/* webpackChunkName: "swiper" */ 'swiper'),
      import(/* webpackChunkName: "swiper" */ 'swiper/css/swiper.css'),
    ]).then(([{ default: Swiper }]) => {
      if (!this.$el || !this.$el.querySelector) return;

      this.swiper = new Swiper({
        el: this.$el.querySelector('.swiper-container'),
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        spaceBetween: 40,
        observer: true,
        observeParents: true,
      });
    });
  }

  beforeDestroy() {
    if (this.swiper) this.swiper.destroy();
  }

  get allUncompleted() {
    const uncompleted = Object.entries(this.items).map(([key, value]) => {
      // If value has children return only the uncompleted of them
      if ((value as any).children) {
        return [
          this.completion[key] ? {} : { [key]: value },
          Object.entries((value as any).children)
            // eslint-disable-next-line no-unused-vars
            .filter(([key2, value2]) => !this.completion[key2])
            .map(([key2, value2]) => ({ [key2]: value2 })),
        ];
      }
      // Remove completed entries
      if (this.completion[key]) return {};
      return { [key]: value };
    });

    return this.flatDeep(uncompleted, Infinity).reduce((obj: any, val: any) => {
      return {
        ...obj,
        ...val,
      };
    }, {});
  }

  // Some browsers still doesn't support flat function, so we have a custom flat function
  flatDeep(arr: any, d = 1) {
    return d > 0
      ? arr.reduce((acc: any, val: any) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), [])
      : arr.slice();
  }

  shouldMarkAsDone(childKey: any) {
    return this.completion[childKey];
  }

  get completion() {
    const completion = this.$store.state.account.profileCompletion || {};
    return completion;
  }

  highlightInfoBlock(blockName: string) {
    const el = document.querySelector(`.profile-${blockName}`) as HTMLElement;
    highlight({ el });
  }

  action(itemKey: string) {
    if (itemKey === 'location' || itemKey === 'name' || itemKey === 'contactInfo' || itemKey === 'socialLinks') {
      this.$router.push({
        name: 'user',
        params: { username: this.$store.state.account.username },
        query: { 'edit-profile': 'true' },
      });
    } else if (itemKey === 'workExperience') {
      this.highlightInfoBlock('workexperience');
    } else if (itemKey === 'education') {
      this.highlightInfoBlock('education-certificates');
    } else if (itemKey === 'portfolio') {
      this.highlightInfoBlock('portfolio');
    } else if (itemKey === 'about') {
      this.highlightInfoBlock('about');
    } else if (itemKey === 'profilePicture') {
      highlight({
        el: document.querySelector('.profile-head-avatar') as HTMLElement,
      });
    } else {
      this.highlightInfoBlock('job-preferences');
    }
  }
}

export default ProfileStrength;
