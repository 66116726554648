



























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
class ProfileToggle extends Vue {}

export default ProfileToggle;
