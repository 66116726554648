



















import Vue from 'vue';
import Component from 'vue-class-component';
import { github } from '../../../../store/github';

@Component({})
class ProvidePersonalAccessToken extends Vue {
  token = '';
  url = '';
  get isLoading() {
    return this.$store.state.github.loading;
  }

  submit() {
    if (!this.$refs.form) return;
    // @ts-ignore
    this.$refs.form.validate().then((valid) => {
      if (valid) {
        this.$store.dispatch(github.actions.StartGitHubAuth, { token: this.token });
      }
    });
  }
}
export default ProvidePersonalAccessToken;
