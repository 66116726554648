
















































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { format as formatDate } from 'date-fns';

import { friends, FriendToInvite } from '../../store/friends';

import notification from '../../ui/utils/notification';
import formatScore from '../../utils/format-score';

@Component({
  components: {},
})
class FriendsInvites extends Vue {
  @Prop({ type: Boolean, default: true }) showEmpty: boolean;
  @Prop({ type: Boolean, default: false }) selectAll: boolean;
  @Prop({ type: String, default: 'default' }) variation: string;
  loading: boolean = true;
  tab: string = 'invite';
  selectedIds: string[] = [];
  resentIds: string[] = [];
  reposModalOpened: boolean = false;
  reposModalData: FriendToInvite | null = null;
  emailsModalOpened: boolean = false;
  invitesSending: boolean = false;
  searchQuery: string = '';

  get wrongEmails() {
    return this.$store.state.friends.wrongEmails;
  }

  get errorMessage() {
    return this.$store.state.friends.errorMessage;
  }

  set errorMessage(err) {
    this.$store.commit(friends.mutations.SetError, err);
  }

  get hasFriends() {
    return this.$store.getters[friends.getters.FriendsToInvite].length > 0;
  }

  get friendsToInvite(): FriendToInvite[] {
    return this.$store.getters[friends.getters.FriendsToInvite].filter((friend: FriendToInvite) => {
      return !friend.username && !friend.invitedBefore;
    });
  }

  get friendsToInviteSearched(): FriendToInvite[] {
    const query = this.searchQuery;
    return this.friendsToInvite.filter(
      (friend) =>
        (friend.name && friend.name.indexOf(query) >= 0) || (friend.email && friend.email.indexOf(query) >= 0),
    );
  }

  get friendsRegistered(): FriendToInvite[] {
    return this.$store.getters[friends.getters.FriendsToInvite].filter((friend: FriendToInvite) => {
      return friend.username || friend.invitedBefore;
    });
  }

  get friendsRegisteredSearched(): FriendToInvite[] {
    const query = this.searchQuery;
    return this.friendsRegistered.filter(
      (friend) =>
        (friend.name && friend.name.indexOf(query) >= 0) || (friend.email && friend.email.indexOf(query) >= 0),
    );
  }

  get inviteModalFriends(): FriendToInvite[] {
    return this.friendsToInvite.filter((f) => this.selectedIds.indexOf(f.id) >= 0);
  }

  async mounted() {
    const { $store } = this;

    // load invites data
    $store
      .dispatch(friends.actions.GetFriendsToInvite)
      .then(() => {
        this.loading = false;
        if (this.selectAll === true) {
          Object.values(this.friendsToInviteSearched).forEach((friend, i) => {
            if (i < 10 && !friend.invitedBefore) {
              this.toggleFriend(friend.id);
            }
          });
        }
      })
      .catch(() => {
        this.loading = false;
      });
  }

  unsetWrongEmails() {
    this.$store.dispatch(friends.actions.UnsetWrongEmails);
  }

  formatRepoScore(score: number) {
    return formatScore(score, true);
  }

  formatRepoDate(date: string) {
    return formatDate(new Date(date), 'MMM dd, yyyy');
  }

  openReposModal(friendInvite: FriendToInvite) {
    this.reposModalData = friendInvite;
    this.reposModalOpened = true;
  }

  closeReposModal() {
    this.reposModalOpened = false;
  }

  openEmailsModal() {
    this.emailsModalOpened = true;
    this.friendsToInvite.forEach((f) => {
      f.changedEmail = f.email;
    });
  }

  closeEmailsModal() {
    this.emailsModalOpened = false;
    this.friendsToInvite.forEach((f) => {
      delete f.editing;
      f.changedEmail = f.email;
    });
  }

  toggleFriend(id: string) {
    if (this.selectedIds.indexOf(id) < 0) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
    }
    if (!this.selectedIds.length) {
      this.closeEmailsModal();
    }
  }

  toggleSelection(e: any) {
    e.preventDefault();
    const friendsToInvite = this.friendsToInvite.filter((friend) => !friend.invitedBefore);
    if (this.selectedIds.length < friendsToInvite.length) {
      this.selectedIds = friendsToInvite.map((friend) => friend.id);
    } else {
      this.selectedIds = [];
    }
  }

  resendInvite(friendToInvite: FriendToInvite) {
    this.resentIds.push(friendToInvite.id);
    this.$store
      .dispatch(friends.actions.SendInvites, {
        emails: [
          {
            name: friendToInvite.name,
            email: friendToInvite.email,
          },
        ],
      })
      .then(() => {
        notification({
          text: `Invite has been sent to ${friendToInvite.name || friendToInvite.email}`,
        });
      });
  }

  sendInvites() {
    if (this.invitesSending) return;
    const { friendsToInvite, selectedIds } = this;
    const userNames = friendsToInvite
      .filter((friend) => selectedIds.indexOf(friend.id) >= 0)
      .map((friend) => friend.name || friend.changedEmail || friend.email)
      .join(', ');

    const emails = friendsToInvite
      .filter((f) => this.selectedIds.indexOf(f.id) >= 0)
      .filter((f) => !!(f.changedEmail && f.changedEmail.trim()))
      .map(({ name, changedEmail }) => ({
        name,
        email: changedEmail,
      }));
    friendsToInvite.forEach((f) => {
      if (f.changedEmail) f.email = f.changedEmail;
    });
    this.$ga.event('invitationFeature', 'invitesSent', this.variation, emails.length);
    this.invitesSending = true;
    this.$store
      .dispatch(friends.actions.SendInvites, { emails })
      .then(() => {
        this.selectedIds = [];
        this.closeEmailsModal();
        this.invitesSending = false;
        notification({
          text: `Invite has been sent to ${userNames}`,
        });
      })
      .catch(() => {
        this.invitesSending = false;
      });
  }
}

export default FriendsInvites;
