
























import Vue from 'vue';
import Component from 'vue-class-component';
import { getUTCDate } from '../../utils/date';
import notification from '../../ui/utils/notification';

@Component({})
class JobInterest extends Vue {
  currentJobInterest: string = '';
  jobInterest: any = {};
  jobInterestOptions: string[] = [
    'Actively looking for a job',
    'Not looking, but open to offers',
    'Not available for hire',
  ];

  remainingDays: Number = 0;
  defaultExpirationPeriod: number = 30;

  created() {
    this.jobInterest = this.$store.state.account.jobInterest;
  }

  mounted() {
    if (!this.jobInterest) {
      this.currentJobInterest = '';
      return;
    }
    this.currentJobInterest = this.jobInterest.status;
    this.remainingDays = this.calculateRemainingDays(this.jobInterest);
    if (this.remainingDays <= 0 && this.jobInterest.status === this.jobInterestOptions[0]) {
      // If expired, set status to 'Not looking, but open to offers'
      const defaultJobInterest = this.jobInterestOptions[1];
      this.$store
        .dispatch('updateJobInterest', {
          interest: defaultJobInterest,
          days: this.defaultExpirationPeriod,
        })
        .then(() => {
          this.jobInterest.status = defaultJobInterest;
        });
    }
  }

  calculateRemainingDays(jobInterest: any) {
    if (!jobInterest || jobInterest.status !== this.jobInterestOptions[0]) {
      return 0;
    }
    const expirationDate = getUTCDate(this.jobInterest.expirationDate);
    const currentDate = new Date();

    return Math.round((expirationDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));
  }

  get showRemainingDays() {
    return this.jobInterest && this.jobInterest.status === this.jobInterestOptions[0] && this.remainingDays > 0;
  }

  changeJobInterest(jobInterestText: string) {
    this.$store
      .dispatch('updateJobInterest', {
        interest: jobInterestText,
        days: this.defaultExpirationPeriod,
      })
      .then(() => {
        const currentDate = new Date();
        this.jobInterest = {
          status: jobInterestText,
          updateAt: currentDate.toString(),
          expirationDate: new Date(currentDate.getTime() + this.defaultExpirationPeriod * (1000 * 3600 * 24)),
        };
        this.remainingDays = this.calculateRemainingDays(this.jobInterest);
        notification({ text: 'Job Interest updated successfully' });
      })
      .catch((err) => {
        notification({ text: "Couldn't update job interest" });
        this.$sentry.captureException(err);
      });
  }

  getIcon(jobInterestText: string) {
    if (jobInterestText === this.jobInterestOptions[0]) {
      return '<i class="fas fa-search mr-2">';
    }
    if (jobInterestText === this.jobInterestOptions[1]) {
      return '<i class="far fa-check-circle mr-2">';
    }
    if (jobInterestText === this.jobInterestOptions[2]) {
      return '<i class="far fa-times-circle mr-2">';
    }
    return '';
  }
}
export default JobInterest;
