












































































import SHA256 from 'crypto-js/sha256';
import Vue from 'vue';
import { Component, Watch } from 'nuxt-property-decorator';
import Loader from '../../../components/shared/Loader.vue';
import InvitationForm from '../../../components/shared/InvitationForm.vue';
import ProfileHead from '../../../components/profile/ProfileHead.vue';
import ProfileSidebar from '../../../components/profile/ProfileSidebar.vue';
import PageFooter from '../../../components/profile/Footer.vue';
import ProfileWidgetsBanner from '../../../components/profile/ProfileWidgetsBanner.vue';

import DetailedSkillModal from '../../../components/profile/Skills/DetailedSkillModal.vue';
import ProfileEditModal from '../../../components/profile/ProfileEditModal.vue';
import FriendsInvites from '../../../components/invite/FriendsInvites.vue';

import About from '../../../components/profile/About.vue';
import Languages from '../../../components/profile/Languages.vue';
import Technologies from '../../../components/profile/Technologies.vue';
import ScoreProgressChart from '../../../components/profile/ScoreProgressChart.vue';
import Scores from '../../../components/profile/Scores.vue';
import Timeline from '../../../components/profile/Timeline.vue';
import Activity from '../../../components/profile/Activity.vue';
import WorkExperience from '../../../components/profile/WorkExperience.vue';
import Education from '../../../components/profile/Education.vue';
import Portfolio from '../../../components/profile/Portfolio.vue';

import { InviteStatus } from '../../../store/index';
import { privaterepo } from '../../../store/privaterepo';
import { education } from '../../../store/education';
import { detailedscore } from '../../../store/detailedscore';
import { gitlab } from '../../../store/gitlab';

import meta from '../../../meta';
import storage from '../../../utils/storage';
import { portfolio } from '../../../store/portfolio';
import { friends } from '../../../store/friends';

@Component({
  components: {
    ProfileHead,
    ProfileSidebar,
    Loader,
    InvitationForm,
    PageFooter,
    DetailedSkillModal,
    ProfileEditModal,
    FriendsInvites,
    ProfileWidgetsBanner,

    About,
    Languages,
    Technologies,
    ScoreProgressChart,
    Scores,
    Timeline,
    Activity,
    WorkExperience,
    Education,
    Portfolio,
  },
  head() {
    const m = meta({
      title: `${this.$route.params.username}'s CodersRank profile`,
      description: 'Measure your skills and knowledge, compare them to market and find a personalized way to grow.',
      image: this.$store.state.profile.screenshot,
    });
    m.meta.push(
      ...[
        {
          name: 'robots',
          content: 'noindex',
        },
        {
          name: 'googlebot',
          content: 'noindex',
        },
      ],
    );
    return m;
  },
})
class Profile extends Vue {
  isLoading = false;
  inviteStatus = InviteStatus.NONE;
  profileEditOpened = false;
  showFriendInvite = false;
  friendInviteVariation = '';

  async fetch({ store, route, redirect }: any) {
    const username = route.params.username;
    const token = route.query.token;
    const multiToken = route.query.multiToken;

    // Check username
    if (!username && !token && !multiToken) {
      redirect('/');
      return;
    }
    if (username && username.trim() !== username) {
      redirect(`/user/${username.trim()}`);
      return;
    }
    store.state.inviteStatus = InviteStatus.NONE;

    // Get account
    try {
      await store.dispatch('tryToGetAccount');
    } catch (err) {
      // error captured in store
    }
    if (token || multiToken) return;

    // Get profile
    try {
      await store.dispatch('getProfile', { username, type: 'user' });
      if (store.state.profile.status === 'registered') {
        try {
          await store.dispatch(education.actions.AssignEducation, username);
        } catch (err) {
          // err
        }
        try {
          await store.dispatch(portfolio.actions.GetPortfolio, username);
        } catch (err) {
          // err
        }
      }
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        redirect('/');
        return;
      }
      if (err && err.message === 'missing_data' && username === `${SHA256(store.state.account.username)}`) {
        return;
      }
    }

    // Set is private view
    if (store.state.profile.ownProfile && (route.name === 'user' || route.name === 'userInfo')) {
      store.commit('setShouldShowPrivateView', true);
    } else {
      store.commit('setShouldShowPrivateView', false);
    }

    if (store.state.profile.status !== 'registered') {
      store.state.inviteStatus = InviteStatus.OVERLAY_MESSAGE;
    }
  }

  get hasFriends() {
    return this.$store.getters[friends.getters.FriendsToInvite].length > 0;
  }

  get showSummaryLink() {
    const registrationDate = new Date(this.$store.state.account.registrationDate.replace(' ', 'T'));
    const hasDataBeforeDate = new Date(2019, 11, 11);
    return registrationDate.getTime() < hasDataBeforeDate.getTime();
  }

  get overlaySub() {
    if (this.hasProfileLoadError) {
      return 'Our team has been alerted and we are fixing the problem.';
    }
    if (this.shouldShowLoggedOutOverlay) {
      return "Sign up now and send your friend an invitation and we'll create their profile page.";
    }
    return "You can only access registered profiles. Send your friend an invitation and we'll create their profile page.";
  }

  get overlayButtonTitle() {
    if (this.shouldShowLoggedOutOverlay) {
      return 'Sign up and Invite';
    }
    return 'Send Invite';
  }

  get shouldShowOverlay() {
    return (
      this.hasProfileLoadError ||
      this.shouldShowLoggedInOverlay ||
      this.shouldShowLoggedOutOverlay ||
      this.$store.state.inviteStatus === InviteStatus.EMAIL_FORM ||
      this.$store.state.inviteStatus === InviteStatus.SENT
    );
  }

  get shouldShowLoggedInOverlay() {
    return (
      !this.hasProfileLoadError &&
      this.$store.state.profile.status !== 'registered' &&
      this.$store.state.isLoggedIn === true &&
      this.$store.state.inviteStatus === InviteStatus.OVERLAY_MESSAGE
    );
  }

  get hasProfileLoadError() {
    return this.$store.state.errorOnProfilePage.errorCode !== '';
  }

  get shouldShowLoggedOutOverlay() {
    return (
      !this.hasProfileLoadError &&
      this.$store.state.profile.status !== 'registered' &&
      this.$store.state.isLoggedIn === false &&
      this.$store.state.inviteStatus === InviteStatus.OVERLAY_MESSAGE
    );
  }

  get hasLanguages() {
    return this.$store.state.profile.scoreBySkills && Object.keys(this.$store.state.profile.scoreBySkills).length > 0;
  }

  get hasTechnologies() {
    return (
      this.$store.state.profile.scoreByLibraries && Object.keys(this.$store.state.profile.scoreByLibraries).length > 0
    );
  }

  @Watch('$route.query')
  onQueryChange() {
    if (process.client && this.$route.query && this.$route.query['edit-profile']) {
      this.profileEditOpened = true;
      this.$router.replace({ name: this.$route.name as string, params: this.$route.params });
    }
  }

  mounted() {
    if (storage.get('terms-accepted')) {
      storage.remove('terms-accepted');
    }
    const store = this.$store;
    const username = this.$route.params.username;

    const { $store } = this;

    // Set A/B testing variant for invitation
    if (!storage.get('friendsInviteVariation')) {
      const r = Math.random();
      if (r <= 0.5) {
        storage.set('friendsInviteVariation', '60 sec');
        storage.set('friendsInviteTimeRemaining', 60);
      } else {
        storage.set('friendsInviteVariation', '120 sec');
        storage.set('friendsInviteTimeRemaining', 120);
      }
    }
    this.friendInviteVariation = storage.get('friendsInviteVariation') || 'default';
    if (!storage.get('hasOpenFriendsInviteBeenShown')) {
      $store
        .dispatch(friends.actions.GetFriendsToInvite)
        .then(() => {})
        .catch(() => {});
      setInterval(() => {
        const v = storage.get('friendsInviteTimeRemaining') || '-1';
        let timeRemaining = parseInt(v, 10);
        if (timeRemaining > 0) {
          timeRemaining -= 5;
        }
        storage.set('friendsInviteTimeRemaining', timeRemaining);
        if (timeRemaining === 0 && !storage.get('hasOpenFriendsInviteBeenShown')) {
          storage.set('hasOpenFriendsInviteBeenShown', true);
          if (this.hasFriends) {
            this.openFriendsInvite();
          }
        }
      }, 5000);
    }

    if (this.$route.query && this.$route.query['edit-profile']) {
      this.profileEditOpened = true;
      // @ts-ignore
      this.$router.replace({ name: this.$route.name as string, params: this.$route.params });
    }
    if (this.$route.query && this.$route.query.privaterepo) {
      store.commit(privaterepo.mutations.OpenModal);
      // @ts-ignore
      this.$router.replace({ name: this.$route.name as string, params: this.$route.params });
    }

    // Load sources
    if (store.state.shouldShowPrivateView) {
      // load private sources
      store.dispatch(gitlab.actions.PollOnce);
      store.dispatch('fetchProviders');
      store.dispatch('getPrivateProfileSources').then(() => {
        store.dispatch(privaterepo.actions.AssignRepos);
      });
    } else if (store.state.profile.status === 'registered') {
      // load public sources
      store.dispatch('getPublicProfileSources', username);
    }

    if (this.$route.query.token && store.state.isLoggedIn) {
      this.isLoading = true;
      store
        .dispatch('mergePrivateRepo', {
          token: this.$route.query.token,
          reponame: this.$route.query.reponame,
        })
        .catch((e) => {
          this.isLoading = false;
          console.log('Merging failed');
          console.log(e.message);
        })
        .then(() => {
          this.isLoading = false;
          return this.$router.push({
            name: 'user',
            params: { username: store.state.account.username },
            query: { privaterepo: 'true' },
          });
        });
    }

    if (this.$route.query.multiToken && store.state.isLoggedIn) {
      this.isLoading = true;
      store
        .dispatch('mergeMultiPrivateRepo', {
          token: this.$route.query.multiToken,
        })
        .catch((e) => {
          this.isLoading = false;
          console.log('Multi repo merging failed');
          console.log(e.message);
        })
        .then(() => {
          this.isLoading = false;
          return this.$router.push({
            name: 'user',
            params: { username: store.state.account.username },
            query: { privaterepo: 'true', multiTokenButton: 'true' },
          });
        });
    }

    if (storage.get('registrationStatus') != null) {
      this.$router.push(`/onboarding/${storage.get('registrationStatus')}`);
    }

    if (storage.get('needsProfileRefresh') === 'true') {
      this.$store.dispatch('refreshProfile', false);
    }
  }

  async overlayButtonAction() {
    if (this.shouldShowLoggedOutOverlay) {
      this.$router.push('/register');
    } else if (this.$store.state.profile.hasUsableEmail) {
      await this.$store.dispatch('inviteMyFriend', { username: this.$store.state.profile.username });
      this.$store.state.inviteStatus = InviteStatus.SENT;
    } else {
      this.$store.state.inviteStatus = InviteStatus.EMAIL_FORM;
    }
  }

  closeFriendsInvite() {
    this.showFriendInvite = false;
  }

  openFriendsInvite() {
    const inviteVariant = storage.get('friendsInviteVariation') || 'default';
    this.$ga.event('invitationFeature', 'openFriendsInvite', inviteVariant);
    this.showFriendInvite = true;
  }

  closeDetailedScore() {
    this.$store.commit(detailedscore.mutations.CloseModal);
  }

  openSidebar() {
    this.$root.$emit('openSidebarPanel');
  }
}

export default Profile;
