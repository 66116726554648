




















































import axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
})
class JobOffers extends Vue {
  pageNumber = 1;
  pageSize = 3;
  offers = [] as any[];
  loading = false;
  totalOffers = 0;
  hasOffers = true;

  clickJobOffer(url: string, jobTitle: string) {
    this.$ga.event('profilePage', 'clickOnJobRecommendation', url);
    // @ts-ignore
    axios.post(`${axios.defaults.gatewayBase}/metrics/clickJobOffer`, {
      jobTitle,
      url,
    });
  }

  getOffers() {
    const { pageNumber, pageSize } = this;
    this.loading = true;
    axios
      .post('https://api.codersrank.io/jobrecommender/job/GetOffersByUsername', {
        pageNumber,
        pageSize,
        username: this.$store.state.profile.username,
      })
      .then((res: any) => {
        this.loading = false;
        this.offers = res.data.offers || [];
        this.totalOffers = res.data.totalOffers || 0;
        if (!this.totalOffers) this.hasOffers = false;
        this.offers.forEach((offer: any) => {
          // @ts-ignore
          axios.post(`${axios.defaults.gatewayBase}/metrics/showJobOffer`, {
            jobTitle: offer.title,
            url: offer.detailsURL,
          });
        });
      })
      .catch(() => {
        this.loading = false;
        this.hasOffers = false;
      });
  }

  hasPrev() {
    return !this.loading && this.pageNumber > 1;
  }

  hasNext() {
    const totalPages = Math.ceil(this.totalOffers / this.pageSize);
    return !this.loading && this.pageNumber < totalPages;
  }

  prev() {
    if (!this.hasPrev()) return;
    this.pageNumber -= 1;
    this.getOffers();
  }

  next() {
    if (!this.hasNext()) return;
    this.pageNumber += 1;
    this.getOffers();
  }

  mounted() {
    this.getOffers();
  }
}
export default JobOffers;
