






















import Vue from 'vue';
import Component from 'vue-class-component';
import Draggable from 'vuedraggable';
import LanguageCard from './Languages/LanguageCard.vue';
import ProfileBlock from './ProfileBlock.vue';

@Component({
  components: {
    ProfileBlock,
    LanguageCard,
    Draggable,
  },
})
class ProfileLanguages extends Vue {
  drag = false;

  get visibleLanguages() {
    let langs: string[] = [];
    if (!this.$store.state.profile.skillOrder) return langs;
    this.$store.state.profile.skillOrder.forEach((sk: string) => {
      if (sk.length > 0) {
        if (
          (this.$store.state.isLoggedIn && this.$store.getters.shouldShowPrivateView) ||
          !this.$store.state.invisibleSkills.includes(sk)
        ) {
          if (!this.$store.state.profile.scoreBySkills[sk]) {
            langs.push(sk);
          } else if (!this.$store.state.profile.scoreBySkills[sk].isHidden) {
            langs.push(sk);
          }
        }
      }
    });
    if (this.$store.state.isLoggedIn && this.$store.getters.shouldShowPrivateView) {
      const visibleLangs = langs.filter((lang) => !this.$store.state.invisibleSkills.includes(lang));
      const invisibleLangs = langs.filter((lang) => this.$store.state.invisibleSkills.includes(lang));
      langs = [...visibleLangs, ...invisibleLangs];
    }

    return langs.filter((language) => !!this.getLanguageDetail(language));
  }

  set visibleLanguages(newOrder) {
    this.$store.dispatch('updateSkillOrder', newOrder);
  }

  getLanguageDetail(language: string) {
    return this.$store.state.profile.scoreBySkills[language];
  }
}

export default ProfileLanguages;
