






























































import Vue from 'vue';
import { ceil } from 'lodash-es';
import { Component, Prop } from 'vue-property-decorator';

import LibraryIcon from '../../shared/LibraryIcon.vue';
import { detailedscore } from '../../../store/detailedscore';
import formatScore from '../../../utils/format-score';
import notification from '../../../ui/utils/notification';

@Component({
  components: {
    LibraryIcon,
  },
})
class ProfileLanguageCard extends Vue {
  @Prop() languageName: string;
  @Prop() language: any;

  isVisible: boolean = !this.$store.state.invisibleSkills.includes(this.languageName);

  get isOutdatedScore(): boolean {
    const profile = this.$store.state.profile;
    if (!profile) return true;
    if (!profile.updatedProfileAt) return false;

    let updateDate: Date;
    try {
      updateDate = new Date(profile.updatedProfileAt);
    } catch (err) {
      // couldn't parse not standard date format
      // 'Oct 28, 2019' -> ['Oct', '28', '2019']
      const dt = profile.updatedProfileAt.split(' ').map((part: string) => part.replace(/,/g, '').trim());
      const months = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');
      updateDate = new Date(dt[2], months.indexOf(dt[0]), dt[1]);
    }
    const targetDate = new Date(2019, 9, 25);
    if (updateDate > targetDate) {
      return false;
    }
    return true;
  }

  get hasDetailedView() {
    return this.$store.getters.shouldShowPrivateView && !this.isOutdatedScore;
  }

  get isMyProfile() {
    return this.$store.state.isLoggedIn && this.$store.getters.shouldShowPrivateView;
  }

  formatScore(number: number): string {
    return formatScore(number, true);
  }

  formatUsersNumber(number: number, addNth: boolean = false): string {
    let suffix = '';
    if (addNth) {
      const textNumber = `${number}`;
      const lastNumber = parseInt(textNumber[textNumber.length - 1], 10);
      const last2Numbers = parseInt(textNumber.slice(textNumber.length - 2), 10);
      if ([11, 12, 13].includes(last2Numbers)) suffix = 'th';
      else if (lastNumber === 1) suffix = 'st';
      else if (lastNumber === 2) suffix = 'nd';
      else if (lastNumber === 3) suffix = 'rd';
      else suffix = 'th';
    }
    if (typeof Intl === 'undefined') return `${number}${suffix}`;
    const formatter = new Intl.NumberFormat('en');
    return `${formatter.format(number)}${suffix}`;
  }

  formatThousands(number: number): string {
    if (number < 1000) return this.formatUsersNumber(number);
    return `${Math.round(number / 1000)}K`;
  }

  tooltipContent(num: number, numAll: number): string {
    return `<span style="opacity: 0.8">Out of ${this.formatUsersNumber(numAll)}</span>`;
  }

  toggleVisibility() {
    const notify = () => {
      notification({
        text: `${this.languageName} language is ${!this.isVisible ? 'NOT' : ''} visible on your public profile.`,
      });
    };
    if (this.isVisible) {
      this.$store.dispatch('addInvisibleSkill', this.languageName).then(notify).catch(console.log);
    } else {
      this.$store.dispatch('removeInvisibleSkill', this.languageName).then(notify).catch(console.log);
    }
    this.isVisible = !this.isVisible;
  }

  openDetailedScoreModal() {
    if (this.isOutdatedScore) return;
    if (this.$store.getters.shouldShowPrivateView) {
      this.$store.dispatch(detailedscore.actions.OpenModalWithLanguage, this.languageName);
    }
  }

  percentage(a: number, b: number): number {
    if (a === 1 && b === 1) {
      return 1;
    }
    return Math.max(0, Math.min(ceil((a / b) * 100), 100));
  }

  trim(s: string): string {
    if (s.length > 20) {
      s = `${s.slice(0, 15)}...`;
    }
    return s;
  }
}
export default ProfileLanguageCard;
