



































import Vue from 'vue';
import Component from 'vue-class-component';
import { bitbucket } from '../../../../store/bitbucket';

@Component({})
class ProvideCredentials extends Vue {
  username = '';
  appPassword = '';
  get isLoading() {
    return this.$store.state.bitbucket.loading;
  }

  submit() {
    if (!this.$refs.form) return;
    // @ts-ignore
    this.$refs.form.validate().then((valid) => {
      if (valid) {
        this.$store.dispatch(bitbucket.actions.StartBitbucketAuth, {
          username: this.username,
          appPassword: this.appPassword,
        });
      }
    });
  }
}
export default ProvideCredentials;
