
















import Vue from 'vue';
import Component from 'vue-class-component';
import { github } from '../../../../store/github';

@Component({})
class StartStep extends Vue {
  nextStep() {
    this.$store.dispatch(github.actions.SetStep, 1);
  }
}
export default StartStep;
