







































































import Vue from 'vue';
import Component from 'vue-class-component';
import formatScore from '../../../utils/format-score';
import ScoresSources from './ScoresSources.vue';

@Component({
  components: {
    ScoresSources,
  },
})
class ScoresRank extends Vue {
  $refs: {
    tooltip: HTMLElement;
  };

  get totalScore() {
    return formatScore(this.$store.state.profile.totalScore || 0, true);
  }

  get worldwideUsersShortened(): string {
    const { worldWideAll } = this.$store.state.profile;
    let value;
    if (worldWideAll > 1000000) {
      value = `${Math.floor(worldWideAll / 1000000)}M`;
    } else if (worldWideAll > 1000) {
      value = `${Math.floor(worldWideAll / 1000)}K`;
    } else {
      value = Math.floor(worldWideAll).toString();
    }
    return value;
  }

  get positionPercentage() {
    const { positionWorldWide, worldWideAll } = this.$store.state.profile;
    return Math.ceil((Math.min(positionWorldWide, worldWideAll) / worldWideAll) * 100);
  }

  get isSenior() {
    return this.positionPercentage <= 10;
  }

  get isOwnProfile() {
    return this.$store.getters.shouldShowPrivateView;
  }

  closeTooltip() {
    // @ts-ignore
    this.$refs.tooltip.close();
  }

  reloadProfilePage() {
    window.location.reload();
  }

  refreshProfile(forceUpdate = false) {
    this.$ga.event('profilePage', 'refreshProfile', 'yes');
    this.$store.dispatch('refreshProfile', forceUpdate);
  }
}
export default ScoresRank;
