































import Vue from 'vue';
import Component from 'vue-class-component';
import StartStep from './GithubModal/StartStep.vue';
import ProvidePersonalAccessToken from './GithubModal/ProvidePersonalAccessToken.vue';
import ProgressStep from './GithubModal/ProgressStep.vue';
import DoneStep from './GithubModal/DoneStep.vue';

@Component({})
class GithubModal extends Vue {
  components = {
    0: StartStep,
    1: ProvidePersonalAccessToken,
    2: ProgressStep,
    3: DoneStep,
  };

  get currentStep() {
    return this.$store.state.github.step;
  }
}
export default GithubModal;
