












































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import storage from '../../utils/storage';

@Component({})
class ProfileWidgetsBanner extends Vue {
  widgetsBannerClosed: boolean | null | string | undefined = true;

  mounted() {
    this.widgetsBannerClosed = storage.get('widgetsBannerClosed');
  }

  closeWidgetsBanner() {
    this.widgetsBannerClosed = 'true';
    storage.set('widgetsBannerClosed', 'true');
  }
}
export default ProfileWidgetsBanner;
