




























import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileStrength from './ProfileStrength.vue';
import ProfileJobOffers from './ProfileJobOffers.vue';
import ProfileShare from './ProfileShare.vue';
import ProfileSidebarInvite from './ProfileSidebarInvite.vue';
import ProfileSidebarFaq from './ProfileSidebarFaq.vue';
import ProfileToggle from './ProfileToggle.vue';
import ProfileStrengthWizard from './ProfileStrengthWizard.vue';

@Component({
  components: {
    ProfileStrength,
    ProfileJobOffers,
    ProfileShare,
    ProfileSidebarInvite,
    ProfileSidebarFaq,
    ProfileToggle,
    ProfileStrengthWizard,
  },
})
class ProfileSidebar extends Vue {
  sidebarPanelOpened = false;
  portalToMenu = false;
  wizardOpened = false;

  mounted() {
    this.$root.$on('openSidebarPanel', this.openSidebarPanel);
    window.addEventListener('resize', this.calcPortal);
    this.calcPortal();
  }

  beforeDestrot() {
    this.$root.$off('openSidebarPanel', this.openSidebarPanel);
    window.removeEventListener('resize', this.calcPortal);
  }

  calcPortal() {
    this.portalToMenu = window.innerWidth < 992;
  }

  openSidebarPanel() {
    this.sidebarPanelOpened = true;
  }

  closeSidebarPanel() {
    this.sidebarPanelOpened = false;
  }

  openWizard() {
    this.wizardOpened = true;
  }

  closeWizard() {
    this.wizardOpened = false;
  }
}

export default ProfileSidebar;
