














import Vue from 'vue';
import Component from 'vue-class-component';
import { gitlab } from '../../../../store/gitlab';

@Component({})
class DoneStep extends Vue {
  get max() {
    return this.$store.getters[gitlab.getters.SelectedProgress].totalRepos || 0;
  }

  closeHandler() {
    this.$store.dispatch(gitlab.actions.CloseModal);
    this.$store.dispatch('refreshProfile');
  }

  backHandler() {
    if (this.$store.getters[gitlab.getters.IsGitlabComLinked]) {
      this.$store.dispatch(gitlab.actions.SetStep, 1);
    } else {
      this.$store.dispatch(gitlab.actions.SetStep, 0);
    }
    this.$store.dispatch(gitlab.actions.OpenModal);
  }
}
export default DoneStep;
