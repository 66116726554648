function popupTop() {
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth;
  const height = window.innerHeight;

  const systemZoom = width / window.screen.availWidth;
  const top = (height - 480) / 2 / systemZoom + dualScreenTop;

  return top;
}

function popupLeft() {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const width = window.innerWidth;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - 800) / 2 / systemZoom + dualScreenLeft;

  return left;
}

function popup(url: string) {
  const top = popupTop();
  const left = popupLeft();
  window.open(
    url,
    '_blank',
    `width=800, height=480, left=${left}, top=${top}, scrollbars=no, menubar=no, resizable=yes, directories=no, location=no`,
  );
}

export default {
  facebook(url: string, text?: string) {
    const shareUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}${
      text ? `&t=${encodeURIComponent(text)}` : ''
    }`;
    popup(shareUrl);
  },
  twitter(url: string, text?: string) {
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}${
      text ? `&text=${encodeURIComponent(text)}` : ''
    }`;
    popup(shareUrl);
  },
  // eslint-disable-next-line
  linkedin(url: string, text?: string) {
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&latest`;
    popup(shareUrl);
  },
};
