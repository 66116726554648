




















import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileBlock from './ProfileBlock.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    ProfileBlockWidgetButton,
  },
})
class Activity extends Vue {
  hasActivities = true;
  totalActivities = 0;

  mounted() {
    if (window.customElements && !window.customElements.get('codersrank-activity')) {
      import(/* webpackChunkName: "codersrank-activity" */ '@codersrank/activity').then(
        ({ default: CodersRankActivity }) => {
          window.customElements.define('codersrank-activity', CodersRankActivity);
        },
      );
    }
  }

  onActivityData(e: any) {
    this.totalActivities = e.detail.total;
    if (this.totalActivities === 0) this.hasActivities = false;
  }
}

export default Activity;
