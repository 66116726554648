



















import Vue from 'vue';
import Component from 'vue-class-component';
import { bitbucket } from '../../../../store/bitbucket';

@Component({})
class StartStep extends Vue {
  nextStep() {
    this.$store.dispatch(bitbucket.actions.SetStep, 1);
  }
}
export default StartStep;
