































import Vue from 'vue';
import Component from 'vue-class-component';
import StartStep from './GitlabModal/StartStep.vue';
import ProvideSelfHostedDetails from './GitlabModal/ProvideSelfHostedDetails.vue';
import ProgressStep from './GitlabModal/ProgressStep.vue';
import DoneStep from './GitlabModal/DoneStep.vue';

@Component({})
class GitlabModalBody extends Vue {
  components = {
    0: StartStep,
    1: ProvideSelfHostedDetails,
    2: ProgressStep,
    3: DoneStep,
  };

  get currentStep() {
    return this.$store.state.gitlab.step;
  }
}
export default GitlabModalBody;
