






































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
class SidebarFaq extends Vue {}

export default SidebarFaq;
