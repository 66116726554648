












import Vue from 'vue';
import Component from 'vue-class-component';
import { bitbucket } from '../../../../store/bitbucket';

@Component({})
class DoneStep extends Vue {
  get max() {
    return this.$store.state.bitbucket.repos.totalRepos || 0;
  }

  closeHandler() {
    this.$store.dispatch('refreshProfile', false);
    this.$store.dispatch(bitbucket.actions.CloseModal);
  }
}
export default DoneStep;
