interface HighlightParameters {
  /** HTML Element to highlight */
  el: HTMLElement;
  /** Extra offset around element (default 10) */
  offset?: number;
  /** Scroll to element */
  scroll?: boolean;
}

let highlightOpened = false;

export default function highlight(parameters = {} as HighlightParameters) {
  const { el, offset = 10, scroll = true } = parameters;
  if (!el || highlightOpened) return;

  const rootEl = document.querySelector('#app') as HTMLElement;
  const { offsetWidth: rootWidth, offsetHeight: rootHeight } = rootEl;

  const highlightEl = document.createElement('div');
  highlightEl.classList.add('cr-highlight');

  function setHighlight() {
    const rect = el.getBoundingClientRect();
    const top = rect.top + window.scrollY;
    const left = rect.left + window.scrollX;
    const { width, height } = rect;

    // @ts-ignore
    highlightEl.style.left = `${left - offset}px`;
    highlightEl.style.top = `${top - offset}px`;
    highlightEl.style.width = `${width + offset * 2}px`;
    highlightEl.style.height = `${height + offset * 2}px`;
    // @ts-ignore
    highlightEl.style.setProperty('--cr-highlight-size', `${Math.max(rootWidth, rootHeight)}px`);
  }

  function closeHighlight() {
    window.removeEventListener('resize', setHighlight);
    document.removeEventListener('click', closeHighlight);
    highlightEl.addEventListener('animationend', () => {
      rootEl.style.pointerEvents = '';
      el.style.pointerEvents = '';
      highlightOpened = false;
      rootEl.removeChild(highlightEl);
    });
    highlightEl.classList.add('cr-highlight-out');
  }

  setHighlight();
  setTimeout(() => {
    window.addEventListener('resize', setHighlight);
    document.addEventListener('click', closeHighlight);
  });

  // @ts-ignore
  rootEl.appendChild(highlightEl);
  rootEl.style.pointerEvents = 'none';
  el.style.pointerEvents = 'auto';
  highlightOpened = true;

  if (scroll) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  // eslint-disable-next-line
  return highlightEl;
}
