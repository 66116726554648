






















































import Vue from 'vue';
import Component from 'vue-class-component';
import RankingBadge from '../../shared/RankingBadge.vue';

@Component({
  components: {
    RankingBadge,
  },
})
class Badges extends Vue {
  get hasBadges(): boolean {
    return this.$store.getters.getAllBadges;
  }

  get hasHighlightedBadges(): boolean {
    if (!this.$store.getters.getAllBadges) return false;
    return this.$store.getters.getAllBadges.filter((item: any) => item.visibility === 'highlighted').length > 0;
  }

  get isEditable() {
    return this.$store.getters.shouldShowPrivateView && this.$store.state.isLoggedIn;
  }

  get highlighted() {
    return this.$store.getters.getAllBadges.filter((item: any) => item.visibility === 'highlighted');
  }

  get allPublicBadges() {
    return this.$store.getters.getAllBadges.filter(
      (item: any) => item.visibility === 'highlighted' || item.visibility === 'public',
    );
  }

  get allBadgeLength() {
    return this.allPublicBadges.length;
  }

  get showAllBadges() {
    if (this.allBadgeLength === 0) {
      return false;
    }
    if (this.highlighted.length === this.allBadgeLength) {
      return false;
    }
    return true;
  }

  get isOwnProfile() {
    return this.$store.state.profile.ownProfile && this.$route.name !== 'userAsOthersSee';
  }
}
export default Badges;
