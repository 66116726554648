










































































































































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { isValid, parse, subYears } from 'date-fns';
import { responseError } from '../../utils/response-error';
import LocationInput from '../shared/LocationInput.vue';

@Component({
  components: {
    LocationInput,
  },
})
class ProfileEditModal extends Vue {
  @Prop({ type: Boolean }) inline: boolean;

  saving = false;
  errorMessage = '';
  showRefreshModal = false;
  isLocationChanged = false;
  formChanged = false;

  hideProfile = false;
  firstName = '';
  lastName = '';
  country = '';
  city = '';
  dobDay = '';
  dobMonth = '';
  dobYear = '';
  gender = '';
  genderCustom = '';
  contactEmail = '';
  contactPhone = '';
  linkedIn = '';
  website = '';
  twitter = '';

  created() {
    const account = this.$store.state.account;
    if (account.DOB) {
      const date = new Date(account.DOB);
      this.dobDay = `${date.getUTCDate()}`;
      this.dobMonth = `${date.getUTCMonth() + 1}`;
      this.dobYear = `${date.getUTCFullYear()}`;
    }
    this.hideProfile = account.hideProfile || false;
    this.firstName = account.firstName || '';
    this.lastName = account.lastName || '';
    this.contactEmail = account.contactEmail || '';
    this.contactPhone = account.contactPhone || '';
    this.linkedIn = account.linkedIn;
    this.website = account.website || '';
    this.twitter = account.twitter || '';
    this.country = account.country || '';
    this.city = account.city || '';

    let gender;
    let genderCustom;
    if (!account.gender) {
      gender = '';
    } else if (['Male', 'Female', 'Custom', 'Prefer not to say'].includes(account.gender)) {
      gender = account.gender || '';
    } else {
      gender = 'Custom';
    }

    if (account.gender === 'Custom') {
      genderCustom = '';
    } else {
      genderCustom = account.gender || '';
    }

    this.gender = gender;
    this.genderCustom = genderCustom;
  }

  get parsedDOB() {
    return parse(
      `${this.dobYear}-${this.dobMonth.padStart(2, '0')}-${this.dobDay.padStart(2, '0')}`,
      'yyyy-MM-dd',
      new Date(),
    );
  }

  get isDateValid() {
    if (!(this.dobDay || this.dobMonth || this.dobYear)) return true;
    if (!isValid(this.parsedDOB)) return false;
    return this.parsedDOB < subYears(new Date(), 10);
  }

  @Watch('city')
  @Watch('country')
  onLocationChange(newV: string, oldV: string) {
    if (newV && !oldV) return;
    this.isLocationChanged = true;
  }

  updateProfile() {
    this.saving = true;
    let { linkedIn, website, twitter } = this;
    /* eslint-disable */
    if (linkedIn.indexOf('javascript:') >= 0) linkedIn = '';
    if (website.indexOf('javascript:') >= 0) website = '';
    if (twitter.indexOf('javascript:') >= 0) twitter = '';
    /* eslint-enable */
    return this.$store
      .dispatch('updateProfile', {
        email: this.$store.state.account.email,
        firstName: this.firstName,
        lastName: this.lastName,
        country: this.country,
        city: this.city,
        specialization: this.$store.state.account.developerTitle,
        gender: this.gender,
        DOB: isValid(this.parsedDOB) ? this.stripTimezoneRFC3339(this.parsedDOB) : null,
        contactEmail: this.contactEmail,
        contactPhone: this.contactPhone,
        linkedIn,
        website,
        twitter,
      })
      .then(() => {
        this.saving = false;
      })
      .catch((err) => {
        this.saving = false;
        this.$sentry.captureException(err);
        return Promise.reject(err);
      });
  }

  updateLocation(city: string, country: string) {
    this.city = city;
    this.country = country;
    this.formChanged = true;
  }

  toggleProfileVisibility(isHidden: boolean) {
    this.$store.dispatch('updateProfileVisibility', { isHidden });
  }

  stripTimezoneRFC3339(date: Date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T00:00:00Z`;
  }

  close() {
    this.$emit('close');
  }

  openRefreshModal() {
    this.showRefreshModal = true;
  }

  closeRefreshModal() {
    this.$ga.event('profilePage', 'refreshProfile', 'no');
    this.showRefreshModal = false;
  }

  save() {
    if (this.saving) return Promise.reject();
    // @ts-ignore
    return this.$refs.form.validate().then((valid: boolean) => {
      if (!valid || !this.isDateValid) return Promise.reject();
      if (this.isLocationChanged) {
        this.openRefreshModal();
        return Promise.reject();
      }
      return this.updateProfile()
        .then(() => {
          this.isLocationChanged = false;
          this.close();
        })
        .catch((err) => {
          this.displayError(err);
        });
    });
  }

  displayError(err: any) {
    this.closeRefreshModal();
    this.errorMessage = responseError(err, 'Unable to update your account. Please try again later.');
  }

  refreshProfile() {
    this.showRefreshModal = false;
    this.$ga.event('profilePage', 'refreshProfile', 'yes');
    this.updateProfile()
      .then(() => {
        this.$store.dispatch('refreshProfile');
        this.isLocationChanged = false;
        this.close();
      })
      .catch((err) => {
        this.displayError(err);
      });
  }
}
export default ProfileEditModal;
