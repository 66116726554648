






































import Vue from 'vue';
import Component from 'vue-class-component';
import { format } from 'date-fns';
import ProfileBlock from './ProfileBlock.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    ProfileBlockWidgetButton,
  },
})
class ScoreProgressChart extends Vue {
  hasSkillsChart = true;
  highestScore = 0;
  highestScoreDate = '';
  errored = false;

  get visibleSkills(): string[] {
    const { profile, isLoggedIn, invisibleSkills } = this.$store.state;
    const skills = (profile.skillOrder || []).filter(
      (sk: string) =>
        sk.length > 0 &&
        ((isLoggedIn && this.$store.getters.shouldShowPrivateView) || !invisibleSkills.includes(sk)) &&
        profile.scoreBySkills &&
        profile.scoreBySkills[sk] &&
        !profile.scoreBySkills[sk].isHidden,
    );
    if (!skills.length) skills.push('none');
    return skills;
  }

  get status(): string {
    return this.$store.state.profile.status;
  }

  mounted() {
    if (this.status === 'not_found' || this.status === 'generated') {
      return;
    }
    if (window.customElements && !window.customElements.get('codersrank-skills-chart')) {
      import(/* webpackChunkName: "codersrank-activity" */ '@codersrank/skills-chart').then(
        ({ default: CodersRankSkillsChart }) => {
          window.customElements.define('codersrank-skills-chart', CodersRankSkillsChart);
        },
      );
    }
  }

  onSkillsChartData(e: any) {
    const { scores, highest } = e.detail;
    this.highestScore = Math.floor(highest.score);
    this.highestScoreDate = format(new Date(highest.date), 'dd MMM yyyy');
    this.hasSkillsChart = !!scores && scores.length > 2;
  }
}
export default ScoreProgressChart;
