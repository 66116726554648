




























import Vue from 'vue';
import Component from 'vue-class-component';
import ProfileBlock from './ProfileBlock.vue';
import ProfileBlockWidgetButton from './ProfileBlockWidgetButton.vue';

@Component({
  components: {
    ProfileBlock,
    ProfileBlockWidgetButton,
  },
})
class Timeline extends Vue {
  hasTimeline = true;

  get isOwnProfile() {
    return this.$store.getters.shouldShowPrivateView;
  }

  mounted() {
    if (window.customElements && !window.customElements.get('codersrank-timeline')) {
      // @ts-ignore
      import(/* webpackChunkName: "codersrank-timeline" */ '@codersrank/timeline/esm/codersrank-timeline').then(
        ({ default: CodersRankTimeline }) => {
          window.customElements.define('codersrank-timeline', CodersRankTimeline);
        },
      );
    }
  }

  addWorkExperience() {
    const weBlockEl = document.querySelector('.profile-workexperience') as HTMLElement;
    if (!weBlockEl) return;
    weBlockEl.scrollIntoView({ behavior: 'smooth' });
  }

  onTimelineData(e: any) {
    this.hasTimeline = e.detail.items.length > 0;
  }
}

export default Timeline;
