












import Vue from 'vue';
import Component from 'vue-class-component';
import { github } from '../../../../store/github';

@Component({})
class DoneStep extends Vue {
  get max() {
    return this.$store.state.github.repos.totalRepos || 0;
  }

  closeHandler() {
    this.$store.dispatch('refreshProfile', false);
    this.$store.dispatch(github.actions.CloseModal);
  }
}
export default DoneStep;
