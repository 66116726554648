



































import Vue from 'vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';

import { InviteStatus } from '../../store/index';

@Component
class InvitationForm extends Vue {
  @State inviteStatus: string;

  email = '';

  get shouldShowInviteForm(): boolean {
    return this.inviteStatus === InviteStatus.EMAIL_FORM;
  }

  get shouldShowInviteSent(): boolean {
    return this.inviteStatus === InviteStatus.SENT;
  }

  async sendInviteClick() {
    // @ts-ignore
    const isValid = await this.$refs.form.validate();
    if (isValid) {
      await this.$store.dispatch('inviteMyFriend', {
        username: this.$store.state.profile.username,
        email: this.email,
      });
      this.$store.state.inviteStatus = InviteStatus.SENT;
    }
  }

  resetState() {
    this.$store.state.inviteStatus = InviteStatus.OVERLAY_MESSAGE;
  }
}

export default InvitationForm;
