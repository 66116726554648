


















import Vue from 'vue';
import Component from 'vue-class-component';
import { gitlab } from '../../../../store/gitlab';

@Component({})
class StartStep extends Vue {
  toSelfHosted() {
    this.$store.dispatch(gitlab.actions.SetStep, 1);
  }

  startGitlabComAuth() {
    this.$store.dispatch(gitlab.actions.StartGitlabAuth);
  }
}
export default StartStep;
